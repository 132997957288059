.mapboxgl-ctrl-group {
    background: #070221;
}

.mapboxgl-ctrl-zoom-in {
    filter: brightness(0) invert(1);
}

.mapboxgl-ctrl-zoom-out {
    filter: brightness(0) invert(1);
}
