.mapboxgl-ctrl-geocoder {
    background-color: transparent;
    border: 1px solid #9C9AA6;
}

.mapboxgl-ctrl-geocoder--icon {
    fill: white;
}

.mapboxgl-ctrl-geocoder--input {
    background-color: #070221;
    border-radius: 6px;
    color: white !important;
}

.mapboxgl-ctrl-geocoder--button {
    background-color: transparent;
}

.mapboxgl-ctrl-geocoder .suggestions {
    background-color: #39354D;
}

.mapboxgl-ctrl-geocoder--suggestion {
    color: white;
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a, .mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
    background-color: #4832CB;
}

@media screen and (min-width: 640px) {
    .mapboxgl-ctrl-geocoder {
        width: 100%;
        max-width: none;
    }
}
