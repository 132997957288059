.mapboxgl-popup-content {
    background: #3A384F;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: #3A384F;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: #3A384F;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: #3A384F;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: #3A384F;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
    border-bottom-color: #3A384F;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
    border-top-color: #3A384F;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border-bottom-color: #3A384F;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    border-top-color: #3A384F;
}
